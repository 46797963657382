import { useSpring, useTransition, animated } from "@react-spring/web";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import HandDown from "../assets/HomePage/carbon_touch.png";
import RactanglePic from "../assets/HomePage/Rectangle.png";
import GroupPic from "../assets/HomePage/grouppic.png";
import Pic1 from "../assets/HomePage/YearOfExcelence.png";
import Pic2 from "../assets/HomePage/SatisfiedStudent.png";
import Pic3 from "../assets/HomePage/ExperienceFaculties.png";
import Pic4 from "../assets/HomePage/HappyParents.png";
import OurAchievers from "../assets/HomePage/OurAchievers.png";
import Stu1 from "../assets/HomePage/stu1.png";
import Stu2 from "../assets/HomePage/stu2.png";
import Stu3 from "../assets/HomePage/stu3.png";
import Stu4 from "../assets/HomePage/stu4.png";
import OurStory from "../assets/HomePage/OurStory_bg.png";
import OurStory1 from "../assets/HomePage/OurStory_bg1.png";
import TopResult from "../assets/HomePage/Top_Results_in_Kalyan.png";
import Educators from "../assets/HomePage/Experienced_Educators.png";
import Learning from "../assets/HomePage/Personalizes Learning.png";
import Facilitis from "../assets/HomePage/Modern Facilites.png";
import GroupPic2 from "../assets/HomePage/GroupPic2.png";
import GroupPic3 from "../assets/HomePage/GroupPic (2).png";
import GroupPic3a from "../assets/HomePage/GroupPica.png";
import Science from "../assets/HomePage/Science.png";
import JEE from "../assets/HomePage/JEE.png";
import NEET from "../assets/HomePage/NEET.png";
import { FaRegStar } from "react-icons/fa";
import Student0 from "../assets/HomePage/Student0.png";
import Student1 from "../assets/HomePage/Student1.png";
import Student2 from "../assets/HomePage/Student2.png";
import Student3 from "../assets/HomePage/Student3.png";
import Watch from "../assets/HomePage/Watch.png";
import Arrow from "../assets/HomePage/RegisterNow Arrow.png";
import Presentor from "../assets/HomePage/Presenter.png";
import GroupPic4 from "../assets/HomePage/GroupPic4.png";
import EventPic1 from "../assets/HomePage/EventsPic1.png";
import EventPic2 from "../assets/HomePage/EventsPic2.jpeg";
import EventPic3 from "../assets/HomePage/EventsPic3.jpeg";
import EventPic4 from "../assets/HomePage/EventsPic4.png";
import EventBG from "../assets/HomePage/Event_bg.png";

const HomePage = () => {
  const navigate = useNavigate();
  const achieversRef = useRef(null);

  const handleScrollToAchievers = () => {
    achieversRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const headings = [
    "11th & 12th Science",
    "IIT-JEE",
    "NEET",
    "MHT-CET",
    "8th-9th-10th Board",
    "JEE/NEET Foundation",
    "Olympiad",
    "Special Math Batch",
  ];

  const [index, setIndex] = useState(0);

  // Transitions for scrolling effect
  const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translateY(100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-100%)" },
    config: { duration: 1000 }, // Transition duration
    onRest: () => {
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % headings.length);
      }, 1000); // Delay between transitions
    },
  });

  const [springProps, api] = useSpring(() => ({
    from: { transform: "translateX(0%)" }, // Start from its current position
    to: { transform: "translateX(-100%)" }, // Move to the left
    config: { duration: 10000 }, // Duration of the scrolling effect
    loop: true, // Set loop to true for continuous motion
  }));

  // Start the animation on component mount
  useEffect(() => {
    api.start();
  }, [api]);

  const profileData = [
    {
      id: 1,
      name: "Gaurav Sanjay",
      profilePic: Stu1,
      Achievements: "MHT-CET",
      Specialization: "Score: 97%tile",
    },
    {
      id: 2,
      name: "Akshata Khatal",
      profilePic: Stu2,
      Achievements: "MHT-CET",
      Specialization: "Score: 75.83%tile",
    },
    {
      id: 3,
      name: "Kunal Parshuram",
      profilePic: Stu3,
      Achievements: "MHT-CET",
      Specialization: "Score: 97%tile",
    },
    {
      id: 4,
      name: "Soham Devidas",
      profilePic: Stu4,
      Achievements: "MHT-CET",
      Specialization: "Score: 92.76%tile",
    },
    {
      id: 5,
      name: "Adnan Feroz",
      profilePic: OurAchievers,
      Achievements: "MHT-CET",
      Specialization: "Score: 95.54%tile",
    },
    {
      id: 6,
      name: "Nishant Pradeep",
      profilePic: OurAchievers,
      Achievements: "MHT-CET",
      Specialization: "Score: 94.46%tile",
    },
  ];
  return (
    <>
      <div>
        <div className="bg-gradient-to-b from-[#406376] to-[#002F48] justify-center font-roboto">
          <div className="container mx-auto sm:flex px-4 pb-6 sm:pb-32">
            <div className="pt-4 sm:pt-[120px] sm:ml-[50px]">
              <h1 className="w-[328px] sm:w-[650px] text-[24px] sm:text-[64px] text-[#ffffff] sm:text-[#000000] font-bold leading-tight">
                Best Coaching Classes in Kalyan for
              </h1>
              <div className="relative h-[50px] w-full flex items-center overflow-hidden">
                {transitions((style, i) => (
                  <animated.h2
                    className="text-[32px] sm:text-[40px] text-[#F67F00] font-source font-bold leading-loose absolute"
                    style={style}
                  >
                    {headings[i]}
                  </animated.h2>
                ))}
              </div>
              <p className="text-[18px] sm:text-[28px] w-[328px] sm:w-[605px] text-[#ffffff] sm:text-black font-roboto font-normal">
                Join Kalyan's Top-Rated Coaching Institute for Academic
                Excellence and Competitive Exam Success.
              </p>
              <div className="flex justify-between sm:justify-normal sm:gap-4 mt-6">
                <button
                  onClick={handleScrollToAchievers}
                  className="flex justify-center items-center bg-[#F47B1E] text-[16px] sm:text-[20px] text-[#ffffff] font-medium w-[165px] h-[50px] sm:w-[229px] sm:h-[62px] rounded-[10px] gap-2 sm:gap-4"
                >
                  Know More
                  <img src={HandDown} alt="" />
                </button>
                <button
                  className="flex justify-center items-center bg-[#ffffff] text-[16px] sm:text-[20px] text-[#031E31] font-medium w-[165px] h-[50px] sm:w-[229px] sm:h-[62px] rounded-[10px]"
                  onClick={() => navigate("/Courses")}
                >
                  View Courses
                </button>
              </div>
            </div>

            <div className="relative mt-8 sm:mt-auto mx-4 sm:mx-auto">
              <img
                className="w-[328px] h-[400px] sm:w-[470px] sm:h-[550px] sm:mt-[60px] sm:ml-[50px]"
                src={RactanglePic}
                alt="RectanglePic"
              />
              <img
                src={GroupPic}
                alt="GroupPic"
                className="absolute top-[0px] mt-3 sm:mt-[100px] ml-7 sm:ml-24 w-[270px] h-[370px] sm:w-[382px] sm:h-[510px]"
              />
            </div>
          </div>
        </div>

        {/* Removed w-[1462px] and used container and max-w-screen-xl */}
        <div className="bg-[#031E31] font-roboto">
          <div className="container px-2 sm:px-0 py-10 mx-auto max-w-screen-xl">
            <div className="flex justify-around">
              <img
                src={Pic1}
                alt="Year Of Excellence"
                className="w-[80px] h-[50px] sm:w-[200px] sm:h-auto"
              />
              <img
                src={Pic2}
                alt="Satisfied Students"
                className="w-[80px] h-[50px] sm:w-[200px] sm:h-auto"
              />
              <img
                src={Pic3}
                alt="Experienced Faculties"
                className="w-[80px] h-[50px] sm:w-[200px] sm:h-auto"
              />
              <img
                src={Pic4}
                alt="Happy Parents"
                className="w-[80px] h-[50px] sm:w-[200px] sm:h-auto"
              />
            </div>
            <div className="flex justify-around">
              <h1 className="text-[#FFFFFF] text-[16px] sm:text-[36px] font-medium ml-2 sm:ml-5">
                10+
              </h1>

              <h1 className="text-[#FFFFFF] text-[16px] sm:text-[36px] font-medium">
                150,000+
              </h1>
              <h1 className="text-[#FFFFFF] text-[16px] sm:text-[36px] font-medium mr-4">
                100+
              </h1>
              <h1 className="text-[#FFFFFF] text-[16px] sm:text-[36px] font-medium sm:ml-5">
                90%
              </h1>
            </div>
          </div>
        </div>

        {/* Achievers Section */}
        <div
          ref={achieversRef} // Reference to the Achievers section
          className="pb-5 sm:pb-20 bg-[#D8E5ED] font-roboto"
        >
          <h1 className="text-center text-[16px] sm:text-[40px] font-semibold text-[#002F48] pt-5 underline underline-offset-[10px] sm:underline-offset-[25px] decoration-[#f77f00]">
            Our Achievers
          </h1>

          <div className="hidden sm:inline ">
            <animated.div style={springProps} className="flex gap-11 mt-20">
              {/* Repeat the profileData twice to create a seamless loop */}
              {[...profileData, ...profileData].map((data, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center flex-none"
                >
                  <div className="h-[190px] w-[190px] bg-[#D8E5ED] rounded-full border-[6px] border-orange-400 flex items-center justify-center">
                    <img
                      className="w-[165px] h-[168px]"
                      src={data.profilePic}
                      alt="Our Achievers"
                    />
                  </div>
                  <h1 className="text-[26px] font-semibold mt-5">
                    {data.name}
                  </h1>
                  <h2 className="text-[26px] text-[#f77f00] font-medium">
                    {data.Achievements}
                  </h2>
                  <h2 className="text-[26px] text-[#f77f00] font-medium">
                    {data.Specialization}
                  </h2>
                </div>
              ))}
            </animated.div>
          </div>

          <div className="overflow-hidden w-full sm:hidden">
            <animated.div style={springProps} className="flex gap-6 mt-5">
              {/* Repeat the profileData2 twice for seamless scrolling */}
              {[...profileData, ...profileData].map((data, index) => (
                <div key={index} className="items-center">
                  <div className="w-[70px] h-[70px] bg-[#D8E5ED] rounded-full border-[3px] border-orange-400 flex items-center justify-center">
                    <img
                      className="w-[60px] h-[60px]"
                      src={data.profilePic}
                      alt="Our Achievers"
                    />
                  </div>
                  <h1 className="text-[12px] w-[100px] font-semibold mt-5">
                    {data.name}
                  </h1>
                  <h2 className="text-[12px] text-[#f77f00] font-medium">
                    {data.Achievements}
                  </h2>
                  <h2 className="text-[12px] text-[#f77f00] font-medium">
                    {data.Specialization}
                  </h2>
                </div>
              ))}
            </animated.div>
          </div>
        </div>
      </div>

      <div className="relative font-roboto">
        <img className="hidden sm:inline  w-screen 2xl:h-[1000px]" src={OurStory} alt="" />
        <img className="sm:hidden h-[1050px] " src={OurStory1} alt="" />
        <div className="absolute w-full sm:flex sm:justify-center 2xl:gap-40 top-0">
          <div className="mt-10 sm:px-4 px-9  sm:pl-[120px]">
            <p className="hidden w-[107px] h-[32px] sm:flex justify-center items-center rounded-[35px] bg-[#031e31]">
              <h2 className=" text[13px] text-[#f37c1d] font-medium">
                OUR STORY
              </h2>
            </p>
            <h1 className="sm:mt-10 text-[16px] sm:text-[40px] text-[#012f48] font-semibold sm:font-bold">
              Why choose SG Education?
            </h1>
            <p className="mt-3 sm:mt-10 w-[328px] sm:w-[433px] text-[14px] sm:text-[18px] text-[#6a6868] font-medium">
              Our coaching classes are renowned for producing top achievers in
              board exams and competitive exams like IIT-JEE and NEET.
            </p>

            <img
              className="sm:hidden  w-[px] h-[px] mt-16 "
              src={GroupPic2}
              alt="StudentPic"
            />

            <div className="flex gap-8 sm:gap-24 mt-16 sm:mt-12">
              <div>
                <img
                  className="w-[58px] h-[53.31px] sm:w-auto sm:h-auto ml-8 sm:ml-0"
                  src={TopResult}
                  alt="TopResult"
                />
                <h1 className="mt-6 text-[14px] sm:text-[20px] font-medium	">
                  Top Results in Kalyan
                </h1>
                <p className="w-[157px] sm:w-[216px] mt-2 sm:mt-4 text-[#6a6868] text-[14px] sm:text-[16px]">
                  Top rank in board & competitive exams
                </p>
              </div>
              <div>
                <img
                  className="w-[58px] h-[53px] sm:w-auto sm:h-auto ml-8 sm:ml-0"
                  src={Educators}
                  alt="Educators"
                />
                <h1 className="mt-6 text-[14px] sm:text-[20px] font-medium">
                  Experienced Educators
                </h1>
                <p className="w-[157px] sm:w-[216px] mt-2 sm:mt-4 text-[#6a6868] text-[14px] sm:text-[16px]">
                  Learn from kalyan’s best educators
                </p>
              </div>
            </div>

            <p className="hidden sm:inline w-[530px] mt-12 border-t-2   "></p>

            <div className="flex gap-8 sm:gap-24 mt-5 sm:mt-12">
              <div>
                <img
                  className="w-[58px] h-[53.31px] sm:w-auto sm:h-auto ml-8 sm:ml-0"
                  src={Learning}
                  alt="Learning"
                />
                <h1 className="mt-6 text-[14px] sm:text-[20px] font-medium">
                  Personalizes Learning
                </h1>
                <p className="w-[157px] sm:w-[216px] mt-2 sm:mt-4 text-[#6a6868] text-[14px] sm:text-[16px]">
                  Each students gets individual attention
                </p>
              </div>
              <div>
                <img
                  className="w-[58px] h-[53.31px] sm:w-auto sm:h-auto ml-8 sm:ml-0"
                  src={Facilitis}
                  alt="Facilitis"
                />
                <h1 className="mt-6 text-[14px] sm:text-[20px] font-medium">
                  Modern Facilites
                </h1>
                <p className="w-[157px] sm:w-[216px] mt-2 sm:mt-4 text-[#6a6868] text-[14px] sm:text-[16px]">
                  equipped with state of the art facilities
                </p>
              </div>
            </div>
            <button
              className="w-[130px] h-[32px] sm:w-[198px] sm:h-[48px] mb-16 sm:mb-0 ml-24 sm:ml-0 mt-[55px] rounded-[10px] shadow-xl shadow-[#f77f00] sm:bg-[#f77f00] border-[1px] border-[#000] sm:border-[#ffffff] "
              onClick={() => navigate("/about")}
            >
              Learn more
            </button>
          </div>
          <div>
            <img
              className="hidden sm:inline mt-20 mr-40"
              src={GroupPic2}
              alt="StudentPic"
            />
          </div>
        </div>
      </div>

      <div className="hidden sm:inline bg-[rgb(215,229,237)]">
        <h1 className="pt-8  flex justify-center text-[40px] text-[#012f48] font-semibold">
          Courses we offer{" "}
        </h1>
        <div className="flex sm:justify-between 2xl:justify-center 2xl:gap-52 px-28 mt-12">
          <div>
            <div className="relative w-[327px] h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
              <img
                className="mx-auto pt-5 w-[285px] h-[205px] mt"
                src={Science}
                alt=""
              />
              <div className="absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 flex justify-center items-center">
                <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
              </div>
              <p className="px-6 mt-2 ">
                Our expert faculty focuses on building a deep understanding of
                concepts...
              </p>
              <div className="flex justify-between mt-8 px-6  ">
                <p className=" font-bold text-[#f77f00]">11th & 12th Science</p>
                <a href="">Go to course</a>
              </div>
            </div>
          </div>

          <div>
            <div className="relative w-[327px] h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
              <img
                className="mx-auto pt-5 w-[285px] h-[205px] mt"
                src={JEE}
                alt=""
              />
              <div className="absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 flex justify-center items-center">
                <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
              </div>
              <p className="px-6 mt-2">
                Our expert faculty focuses on building a deep understanding of
                concepts...
              </p>
              <div className="flex justify-between mt-8 px-6  ">
                <p className=" font-bold text-[#f77f00]">JEE Coaching</p>
                <a href="">Go to course</a>
              </div>
            </div>
          </div>

          <div>
            <div className="relative w-[327px] h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
              <img
                className="mx-auto pt-5 w-[285px] h-[205px] mt"
                src={NEET}
                alt=""
              />
              <div className="absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 flex justify-center items-center">
                <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
              </div>
              <p className="px-6 mt-2 ">
                Our expert faculty focuses on building a deep understanding of
                concepts...
              </p>
              <div className="flex justify-between mt-8 px-6  ">
                <p className=" font-bold text-[#f77f00]">NEET Coaching</p>
                <a href="">Go to course</a>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            className=" my-16 w-[262px] h-[56px] text-[#ffffff] bg-[#f77f00] rounded-[10px]  shadow-neutral-400 shadow-md"
            onClick={() => navigate("/Courses")}
          >
            View All
          </button>
        </div>
      </div>

      <div className="bg-[#012f48]">
        <h1 className="pt-4 sm:pt-8 font-semibold text-[24px] sm:text-[40px] text-center text-[#ffffff]">
          Testomonials
        </h1>
        <img className="sm:hidden mt-4 w-full" src={Student0} alt="" />
        <div className="hidden sm:flex gap-[46.9px] justify-center mt-24 ">
          <img className="flex mt-auto " src={Student1} alt="" />
          <img className=" mb-5" src={Student2} alt="" />
          <img className="flex mt-auto h-[570px]" src={Student3} alt="" />
        </div>
      </div>

      <div className="sm:hidden font-roboto bg-[rgb(215,229,237)]">
        <h1 className="pt-8  flex justify-center text-[15px] sm:text-[40px] text-[#012f48] font-semibold">
          Courses we offer{" "}
        </h1>
        <div className="flex justify-between px-6 sm:px-28 mt-6 sm:mt-12">
          <div className="relative w-[156px] h-[187px] sm:w-[327px] sm:h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
            <img
              className="mx-auto pt-3 sm:pt-5 w-[136px] h-[101px] sm:w-[285px] sm:h-[205px]"
              src={Science}
              alt=""
            />
            <div className="hidden absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 sm:flex justify-center items-center">
              <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
            </div>
            <p className="sm:hidden px-2 mt-2 font-bold text-[12px] text-[#f77f00]">
              11th & 12th Science
            </p>
            <p className="px-2 sm:px-6 mt-1 text-[8px] sm:text-[16px]">
              Our expert faculty focuses on building a deep understanding of
              concepts...
            </p>
            <div className="flex justify-between mt-3 ml-24 sm:mt-8 sm:px-6">
              <p className="hidden sm:incline font-bold text-[#f77f00]">
                11th & 12th Science
              </p>
              <a className="sm:text-[14px] text-[8px] font-bold" href="">
                Go to course
              </a>
            </div>
          </div>

          <div className="relative  w-[156px] h-[187px] sm:w-[327px] sm:h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
            <img
              className="mx-auto pt-3 sm:pt-5 w-[136px] h-[101px] sm:w-[285px] sm:h-[205px]"
              src={JEE}
              alt=""
            />
            <div className="hidden absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 sm:flex justify-center items-center">
              <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
            </div>
            <p className="sm:hidden px-2 mt-2 font-bold text-[12px] text-[#f77f00]">
              JEE Coaching
            </p>
            <p className="px-2 sm:px-6 mt-1 text-[8px] sm:text-[16px]">
              Our expert faculty focuses on building a deep understanding of
              concepts...
            </p>
            <div className="flex justify-between mt-3 ml-24 sm:mt-8 sm:px-6">
              <p className="hidden sm:incline font-bold text-[#f77f00]">
                JEE Coaching
              </p>
              <a className="sm:text-[14px] text-[8px] font-bold" href="">
                Go to course
              </a>
            </div>
          </div>

          <div className="hidden sm:inline relative w-[327px] h-[333px]  bg-[#ffffff] rounded-[10px] shadow-black shadow-md">
            <img
              className="mx-auto pt-5 w-[285px] h-[205px]"
              src={NEET}
              alt=""
            />
            <div className="absolute w-[46px] h-[47px] bg-[#f77f00] rounded-[10px] top-8 right-8 flex justify-center items-center">
              <FaRegStar className="w-[30px] h-[25px] text-[#ffffff]" />
            </div>
            <p className="px-6 mt-2 ">
              Our expert faculty focuses on building a deep understanding of
              concepts...
            </p>
            <div className="flex justify-between mt-8 px-6  ">
              <p className=" font-bold text-[#f77f00]">NEET Coaching</p>
              <a href="">Go to course</a>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <button
            className="text-[14px] sm:text-[16px] my-8 sm:my-16 w-[156px] h-[34px] sm:w-[262px] sm:h-[56px] text-[#ffffff] bg-[#f77f00] rounded-[10px] shadow-neutral-400 shadow-md"
            onClick={() => navigate("/Courses")}
          >
            View All
          </button>
        </div>
      </div>

      <div className="sm:hidden relative font-roboto">
        <div className=" mt-6 py-4 pl-6 bg-[#f77f00]">
          <h1 className="w-[168px] text-[#ffffff] text-[14px] font-bold">
            Ready to Join Kalyan's Best Coaching Classes?
          </h1>
          <button className="w-[84px] h-[26px] mt-2 text-[12px] text-[#f77f00] font-bold bg-[#ffffff]">
            Enroll Now
          </button>
        </div>
        <div className="absolute bottom-0 right-[20px]">
          <img className="w-[144px] h-[135px]" src={Presentor} alt="" />
        </div>
      </div>

      <div className="relative font-roboto">
        <img className="w-screen h-[680px]" src={EventBG} alt="" />
        <div className="absolute top-0 sm:flex 2xl:mx-[20%] mx-4 sm:mx-[68px] ">
          <div className="relative z-10 sm:mt-10">
            <h1 className="pt-9 text-[24px] sm:text-[40px] text-[#012f48] font-semibold">
              Upcoming Events
            </h1>
            <p className="w-[120px] sm:w-[200px] mt-2 sm:mt-3 border-t-2 border-[#f77f00]"></p>
            <div className="sm:hidden flex items-center mt-4 gap-2">
              <img className="w-[9px] h-[9px]" src={Watch} alt="" />
              <p className="text-[#2c2c2c] text-[10px]">Sun, 27 October 2024</p>
            </div>
            <h1 className="w-[300px] sm:w-[570px] text-[16px] sm:text-[32px] font-bold mt-3 sm:mt-8">
              Seminar for SSC Students
            </h1>
            <p className="mt-3 sm:mt-4 w-[328px] sm:w-[490px] text-[12px] sm:text-[24px] text-[#2c2c2c] font-normal">
              Want to score 90% plus in SSC Boards?, Don’t miss our upcoming
              seminar on{" "}
              <span className="font-bold">'Seminar for SSC Students'</span> at
              our <span className="font-bold"> Kalyan Center. </span> Register
              now to secure your spot!
            </p>
            <div className="hidden sm:flex items-center  mt-4 gap-4">
              <img className="h-[20px]" src={Watch} alt="" />
              <p className="text-[#2c2c2c] text-[20px]">
                Sun, 27 October, 2024
              </p>
            </div>
            <button className="flex justify-center items-center gap-3 text-[7.5px] sm:text-[16px] text-[#ffffff] bg-[#f77f00] mt-8 sm:mt-16 w-[80px] h-[24px] sm:w-[190px] sm:h-[48px] rounded-[4px]">
              Register now
              <img
                className="w-[5px] h-[9px] sm:w-auto sm:h-auto"
                src={Arrow}
                alt=""
              />
            </button>
          </div>
          <img
            className=" hidden sm:inline  ml-0 mt-6"
            src={GroupPic3}
            alt=""
          />
          <img className="sm:hidden mx-1 mt-6 " src={GroupPic3} alt="" />
        </div>
      </div>

      <div className="hidden sm:inline relative">
        <div className=" mt-[150px] mx-16 pl-16 bg-[#f77f00] ">
          <h1 className="w-[685px] pt-24 text-[#ffffff] leading-[57px] text-[42px] font-bold">
            Ready to Join Kalyan's Best Coaching Classes?
          </h1>
          <button className="w-[243px] h-[75px] mt-10 mb-10 text-[23px] text-[#f77f00] font-bold bg-[#ffffff]">
            Enroll Now
          </button>
        </div>
        <div className="absolute  bottom-0 pl-[59%] ">
          <img src={Presentor} alt="Presentor" />
        </div>
      </div>

      <div>
        {/* for Mobile */}
        <div className="sm:hidden mt-4 px-4 sm:px-auto sm:mt-[200px]">
          <h1 className=" text-[24px] text-[#012f48] font-semibold">
            Past Events
          </h1>
          <p className="w-[80px] mt-1 border-t-2 border-[#f77f00]"></p>
          <h1 className="mt-6 text-[18px] text-[#012f48] font-bold ">
            Career Opportunities After 12th Science
          </h1>
          <p className="text-[16px] w-[321px] mt-1">
            Our recent seminar on 'Career Opportunities After 12th Science' was
            a huge success, helping students in Kalyan explore various career
            paths.
          </p>
          <img className="sm:hidden mt-10 mb-10" src={GroupPic4} alt="" />
        </div>

          {/* For Large Screen */}
        <div className="relative hidden sm:inline">
          <img className="w-full h-[900px] " src={EventBG} alt="" />
          <div className="absolute flex top-0 mt-10 gap-20 mx-32 sm:justify-center 2xl:px-[20%] ">
            <img className="mb-10" src={GroupPic4} alt="" />
            <div className="mt-64 ">
              <h1 className=" text-[40px] w-[313px] text-[#012f48] font-semibold">
                Past Events
              </h1>
              <p className="w-[150px] mt-3 border-t-2 border-[#f77f00]"></p>
              <h1 className="mt-6 text-[32px] text-[#012f48] w-[448px] font-bold ">
                Career Opportunities After 12th Science
              </h1>
              <p className="text-[24px] w-[448px] mt-5">
                Our recent seminar on 'Career Opportunities After 12th Science'
                was a huge success, helping students in Kalyan explore various
                career paths.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
