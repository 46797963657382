import React from "react";
import insta from "../../images/insta.svg";
import facebook from "../../images/facebook.svg";
import youtube from "../../images/youtube.svg";
import x from "../../images/x.svg";
import location from "../../images/location.svg";
import phone from "../../images/phone.svg";
import mail from "../../images/mail.svg";
import support from "../../images/support.svg";
import section1 from "../../assets/section1.png";
import section2 from "../../assets/section2.png";
import styles from "./NewContact.module.css";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="contact-page">
      <img src={section1} alt="First BG" className="bg-sec1" />
      <div className="contact-page-content">
        <div className="main" style={{ justifyContent : "space-between"}}>
            <div className="cols-1">
                <h1>Get In Touch</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                </p>
            </div>
            <div className="cols-2 display-none">
                <h2>Follow Us On</h2>
              <div className="icon-list">
                <img src={insta} alt="" />
                <img src={facebook} alt="" />
                <img src={youtube} alt="" />
                <img src={x} alt="" />
              </div>
            </div>
        </div>
        <div className="main">
          <div className="column-1">
            <div className={styles.box}>
              <div className={styles.flipContainer}>
                <div className={styles.flipFront}>
                  <img src={location} alt="" />
                  <p>Address</p>
                </div>
                <div className={styles.flipBack}>
                  <img src={location} alt="" />
                  <h2>Address</h2>
                  <h4>
                    Office No 109, Whitefield, Flower valley, Opposite Gurudev
                    NX, Khadakpada, Birla College Road, Kalyan Maharashtra,
                    421301
                  </h4>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.flipContainer}>
                <div className={styles.flipFront}>
                  <img src={phone} alt="" />
                  <p>Phone</p>
                </div>
                <div className={styles.flipBack}>
                  <img src={phone} alt="" />
                  <h2>Phone</h2>
                  <h4>
                    Office No 109, Whitefield, Flower valley, Opposite Gurudev
                    NX, Khadakpada, Birla College Road, Kalyan Maharashtra,
                    421301
                  </h4>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.flipContainer}>
                <div className={styles.flipFront}>
                  <img src={mail} alt="" />
                  <p>Email ID</p>
                </div>
                <div className={styles.flipBack}>
                  <img src={location} alt="" />
                  <h2>Email ID</h2>
                  <h4>
                    Office No 109, Whitefield, Flower valley, Opposite Gurudev
                    NX, Khadakpada, Birla College Road, Kalyan Maharashtra,
                    421301
                  </h4>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.flipContainer}>
                <div className={styles.flipFront}>
                  <img src={support} alt="" />
                  <p>Support Team</p>
                </div>
                <div className={styles.flipBack}>
                  <img src={support} alt="" />
                  <h2>Support Team</h2>
                  <h4>
                    Office No 109, Whitefield, Flower valley, Opposite Gurudev
                    NX, Khadakpada, Birla College Road, Kalyan Maharashtra,
                    421301
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="column-2">
            <div className={styles.formContainer}>
              <h1>Send a Message</h1>
              <form action="">
                <input type="text" name="" id="" placeholder="Full Name" />
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder="Email Id or Phone"
                />
                <div className={styles.textareaContainer}>
                  <textarea
                    name=""
                    id=""
                    placeholder="Write Your message here"
                  ></textarea>
                  <button>Submit</button>
                </div>
                <p>Terms and conditions or Privacy policy</p>
              </form>
            </div>
          </div>
        </div>
        <div className="mapContainer">
          <div className="mapMaintain">
          <h1>Explore in Maps</h1>
          <div className="mapWrapper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.6976948085035!2d73.13538367472482!3d19.     252002446424655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.      1!3m3!1m2!1s0x3be79736706a0099%3A0x710171692ba67415!2sSG%20Education!5e0!3m2!1sen!2sin!4v1727784347118!5m2!1sen!2sin"
              width="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
          </div>
          <img src={section2} alt="Second BG" className="bg-sec2" />
        </div>
      </div>
      
    </div>
  );
}
