import React from "react";
import Logo from "../assets/Footer/Logo.png";
import Phone from "../assets/Footer/phone.png";
import Mail from "../assets/Footer/Mail.png";
import Insta from "../assets/Footer/Insta.png";
import Fb from "../assets/Footer/FB.png";
import YouTube from "../assets/Footer/YouTube.png";

const Footer = () => {
  return (
    <div className="sm:h-auto bg-[#012f48]">
      <div className="flex sm:justify-between py-10 ml-10 gap-5 sm:py-20 sm:mx-40 ">
        <div className="">
          <img
            className="w-[55px] h-[50px] sm:w-auto sm:h-auto"
            src={Logo}
            alt=""
          />
        </div>
        <p className="border-l-[2px] border-[#30566a] h-[500px] sm:h-[300px]"></p>
        <div className="hidden sm:inline">
          <h1 className="text-[20px] text-[#ffffff] underline underline-offset-2">
            Address
          </h1>
          <p className="text-[18px] text-[#ffffff] w-[411px] mt-4">
            Office No 109, Whitefield, Flower Valley, above HDFC Bank, opposite
            Gurudev NX, Khadakpada, Birla College Road, Kalyan,
            Maharashtra 421301.
          </p>
          <h1 className="mt-8 text-[20px] text-[#ffffff] underline underline-offset-2">
            Contact
          </h1>
          <div className="mt-4 flex gap-5">
            <img src={Mail} alt="" />
            <p className="text-[18px] text-[#ffffff]">sgeducation@gmail.com</p>
          </div>
          <div className="mt-4 flex gap-5">
            <img src={Phone} alt="" />
            <p className="text-[18px] text-[#ffffff]">+91 8591932112</p>
          </div>
        </div>
        <div className="hidden sm:inline">
          <h1 className=" text-[20px] text-[#ffffff]">Home</h1>
          <h1 className="mt-6 text-[20px] text-[#ffffff]">Introduction</h1>
          <h1 className="mt-6 text-[20px] text-[#ffffff]">About us</h1>
          <h1 className="mt-[60px] text-[20px] text-[#ffffff] underline underline-offset-2">
            Our Social
          </h1>
          <div className="mt-6 flex justify-between w-[196px]">
            <img src={Insta} alt="" />
            <img src={Fb} alt="" />
            <img src={YouTube} alt="" />
          </div>
        </div>
        <div className="hidden sm:inline">
          <h1 className=" text-[20px] text-[#ffffff]">Events</h1>
          <h1 className="mt-6 text-[20px] text-[#ffffff]">Contact us</h1>
        </div>

        <div className="inline sm:hidden">
          <div>
            <h1 className="text-[14px] text-[#ffffff] font-medium underline underline-offset-2">
              Address
            </h1>
            <p className="text-[14px] text-[#ffffff] w-[195px] mt-4">
              Office No 109, Whitefield, Flower Valley, above HDFC Bank,
              opposite Gurudev NX, Khadakpada, Birla College Road, Kalyan,
              Maharashtra 421301.
            </p>
            <h1 className="mt-8 text-[14px] text-[#ffffff] font-medium underline underline-offset-2">
              Contact
            </h1>
            <div className="mt-4 flex gap-5">
              <img src={Mail} alt="" />
              <p className="text-[14px] text-[#ffffff]">
                sgeducation@gmail.com
              </p>
            </div>
            <div className="mt-4 flex gap-5">
              <img src={Phone} alt="" />
              <p className="text-[14px] text-[#ffffff]">+91 8591932112</p>
            </div>
            <h1 className="mt-6 text-[14px] text-[#ffffff] font-medium underline underline-offset-2">
              Our Social
            </h1>
            <div className="flex mt-6 gap-8">
              <div>
                <h1 className=" text-[14px] text-[#ffffff] font-bold">Home</h1>
                <h1 className="mt-1 text-[14px] text-[#ffffff] font-bold">Introduction</h1>
                <h1 className="mt-1 text-[14px] text-[#ffffff] font-bold">About us</h1>
                <div className=" flex justify-between mt-6">
                  <img className="h-[20px] w-[20px]" src={Insta} alt="" />
                  <img className="h-[20px] w-[20px]" src={Fb} alt="" />
                  <img className="h-[20px] w-[20px]" src={YouTube} alt="" />
                </div>
              </div>
              <div>
                <h1 className=" text-[14px] text-[#ffffff] font-bold">Events</h1>
                <h1 className="mt-1 text-[14px] text-[#ffffff] font-bold">Contact us</h1>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
